import { AccordionDetails } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, IconButton } from "@mui/material";
import { useState } from "react";
import RadioInput from "../../components/Inputs/RadioInput";
import TextInput from "../../components/Inputs/TextInput";
import DropdownInput from "../../components/Inputs/DropdownInput";
import DropdownMultiInput from "../../components/Inputs/DropdownMultiInput";
import AutoCompleteInput from "../../components/Inputs/AutoCompleteInput";
import DialogPopup from "./DialogPopup";
import SwitchInput from "../../components/Inputs/SwitchInput";
import GridTable from "./GridTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Upload from "../../components/Inputs/Upload";
import MultiInputField from "../../components/Inputs/MultiInputField";

const SetFields = (props) => {
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const [expanded, setExpanded] = useState({});
  const userData = props.userData;
  let fields = null;
  if (userData.access.fields && props.heading in userData?.access?.fields) {
    fields = userData?.access?.fields[props.heading];
  }
  const allowAll = userData.user_type === "admin";
  return (
    <span>
      {isArr(props.data) ? (
        props.data.map((field, i) => {
          if (field.type === "radio") {
            return (
              <>
                <RadioInput
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "Input") {
            return (
              <>
                {props && (
                  <TextInput
                    data={props}
                    field={field}
                    disabled={
                      fields && !fields.includes(field.name) && !allowAll
                    }
                  />
                )}
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "MultiInputField") {
            return (
              <>
                <MultiInputField
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "switch") {
            return (
              <>
                <SwitchInput
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "Dropdown") {
            return (
              <>
                <DropdownInput
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "DropdownMulti") {
            return (
              <>
                <DropdownMultiInput
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "AutoComplete") {
            return (
              <>
                <AutoCompleteInput
                  data={props}
                  field={field}
                  disabled={fields && !fields.includes(field.name) && !allowAll}
                />
                {field.line && <hr></hr>}
              </>
            );
          } else if (field.type === "upload") {
            console.log("fn=>",field.name);
            console.log("field.type upload setfields data",props,"field=",field);
            return (
              <Upload
                data={props}
                field={field}
                disabled={fields && !fields.includes(field.name) && !allowAll}
              />
            );
          } else if (field.type === "objectgroup") {
            let counter = props.objCounter[field.name];
            let subCounter =
              props.subObjCounter[props.name + field.name] !== undefined
                ? props.subObjCounter[props.name + field.name][props.index]
                : undefined;
            const nextProps = { ...props };
            nextProps["key"] = field.name;
            nextProps["data"] = field.fields;
            nextProps["name"] =
              props.name !== undefined ? props.name + field.name : field.name;
            nextProps["prodDetails"] =
              props?.subObjDetails[props.name + field.name] ??
              props?.objDetails[field.name] ??
              props?.prodDetails[field.name];
            nextProps["handleSubChange"] =
              props.index !== undefined
                ? props.subFieldChange
                : props.fieldChange;

            return (
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  marginBottom:
                    field.name === "mappings_full_hb_config" ? "100px" : "35px",
                }}
              >
                <label
                  style={{
                    margin: props.name !== undefined ? "10px" : "10px 0",
                  }}
                >
                  {field.label}
                </label>
                {field.name === "hb_full_common_bidders" ||
                field.name === "COUNTRY_KV" ? (
                  <DialogPopup
                    counter={counter}
                    field={field}
                    nextProps={nextProps}
                    {...props}
                  />
                ) : field.name === "mappings_full_hb_config" ? (
                  <>
                    <GridTable
                      field={field}
                      counter={counter}
                      nextProps={nextProps}
                      {...props}
                    />
                  </>
                ) : (
                  counter?.map((el, index) => {
                    let heading =
                      field.name === "mappings_full_hb_config"
                        ? props.prodDetails["mappings_full_hb_config"][
                            index
                          ]?.adUnitName
                            ?.split("/")
                            .pop()
                        : "";
                    nextProps["index"] =
                      props.index !== undefined
                        ? props.index + index.toString()
                        : index;
                    return (
                      <Accordion
                        onChange={(e) => {
                          let temp = expanded;
                          temp[field.name] = temp[field.name] ?? {};
                          temp[field.name][index] =
                            temp[field.name][index] !== "opened"
                              ? "opened"
                              : "closed";
                          setExpanded((prev) => temp);
                          props.setTrigger((prev) => !prev);
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {heading?.length > 0
                            ? heading
                            : field.addButton
                            ? index + 1 + ". " + field.label
                            : field.label}
                        </AccordionSummary>

                        <AccordionDetails>
                          {(expanded[field.name] &&
                            expanded[field.name][index] === "opened") ||
                          index === 0 ? (
                            <div className="objectgroupdiv">
                              <div
                                style={{
                                  border: "1px solid grey",
                                  margin: "5px",
                                  borderRadius: "10px",
                                  width: "96%",
                                }}
                                key={index}
                              >
                                <SetFields {...nextProps} />
                              </div>
                              {field.addButton && index > 0 ? (
                                <IconButton
                                  style={{ height: "10%", margin: "auto" }}
                                  color="error"
                                  onClick={(e) => {
                                    props.name
                                      ? props.deleteSubField(
                                          props.name,
                                          field.name,
                                          props.index,
                                          index
                                        )
                                      : props.deleteField(field.name, index);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                )}
                {props.name ? (
                  <DialogPopup
                    counter={subCounter}
                    field={field}
                    nextProps={nextProps}
                    {...props}
                  />
                ) : (
                  <></>
                )}
                {field.addButton &&
                !props.name &&
                field.name !== "hb_full_common_bidders" ? (
                  <Button
                    sx={{ margin: "5px", width: "5%" }}
                    onClick={
                      props.name !== undefined
                        ? (e) =>
                            props.addSubField(
                              props.name,
                              field.name,
                              props.index
                            )
                        : (e) => props.addField(field.name)
                    }
                  >
                    <AddCircleIcon />
                  </Button>
                ) : (
                  <span></span>
                )}
              </div>
            );
          } else {
            return <></>;
          }
        })
      ) : (
        <></>
      )}
    </span>
  );
};

export default SetFields;
