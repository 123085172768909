import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import productAdditionalFields from "./ProductAdditionalField";
import { trackChange } from "../../utils/TrackChange";
import { setChangedFields } from "../../redux/actions/commonActions";

const SaveGc = (props) => {
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.GenericCube;
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const changedFields = useSelector((state) => state.common.changedField);

  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );
  const notReqFields = useSelector((state) => state.common.notRequiredFields);

  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };

  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str.split("\n");
    return arr;
  };

  // to convert comma seperated string into a array
  const getArrayFromString = (str) => {
    let arr = [];
    if (str) arr = str?.split(",");
    return arr;
  };
  // to convert stringifield array of array into a simple array (timestamps fields in CWs, sizes obj grp field in hb)

  const getSizesArray = (str) => {
    let newStr = "[" + str + "]";
    let arr = JSON.parse(newStr);
    return arr;
  };

  function saveGenericCube() {
    const toSaveObject = { ...addProdDetails };
    let flag = true;
    if (true) {
      field_data.forEach((el) => {
        if (el.type == "objectgroup") {
          let objlen = objectGroupCounter[el.name].length;
          for (let i = 0; i < objlen; i++) {
            let obj = {};
            el.fields.forEach((sub_el) => {
              if (sub_el.type !== "objectgroup") {
                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "textarea_array":
                    obj[sub_el.name] = getArrayFromTextArea(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizesArray(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i];
                    if (sub_el.isBool && typeof obj[sub_el.name] === "string") {
                      obj[sub_el.name] = obj[sub_el.name] === "true";
                    }
                    break;
                }
                if (el.name === "hb_full_common_bidders") {
                }
              } else {
                obj[sub_el.name] = [];
                let subobjlen =
                  subObjectGroupCounter[el.name + sub_el.name][i].length;
                for (let j = 0; j < subobjlen; j++) {
                  let sub_obj = {};
                  sub_el.fields.forEach((in_sub_el) => {
                    switch (in_sub_el.saveType) {
                      case "bool":
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ] === "true";
                        break;
                      case "array":
                        sub_obj[in_sub_el.name] = getArrayFromString(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      case "number":
                        sub_obj[in_sub_el.name] = parseInt(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      default:
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ];
                        if (
                          in_sub_el.isBool &&
                          typeof sub_obj[in_sub_el.name] === "string"
                        ) {
                          sub_obj[in_sub_el.name] =
                            sub_obj[in_sub_el.name] === "true";
                        }
                        break;
                    }
                  });
                  // obj[sub_el.name][j] = sub_obj;
                  if (
                    typeof sub_el.default == "object" &&
                    !isArr(sub_el.default)
                  ) {
                    if (sub_el.index === "static") {
                      obj[sub_el.name] = sub_obj;
                    } else {
                      sub_el.fields.forEach((field) => {
                        let key = field.name;
                        if (
                          Object.keys(sub_obj).length <= 1 &&
                          sub_obj[key] === undefined
                        ) {
                          obj[sub_el.name] = {};
                          return true;
                        } else {
                          obj[sub_el.name][key + j] = sub_obj[key];
                        }
                      });
                    }
                  } else {
                    el.isBool
                      ? (obj[sub_el.name] = sub_obj)
                      : (obj[sub_el.name][i] = sub_obj);
                  }
                }
              }
            });
            el.isBool
              ? (toSaveObject[el.name] = obj)
              : (toSaveObject[el.name][i] = obj);
            if (el.name === "CURRENCY_CONVERSION") {
              if (!obj.enable) {
                toSaveObject[el.name] = false;
              } else {
                toSaveObject["CURRENCY_CONVERSION"] =
                  toSaveObject["CURRENCY_CONVERSION"][0];
              }
            }
          }
        }
        if (el.name === "ad_mappings") {
          if (toSaveObject[el.name].length === 1) {
            if (
              toSaveObject[el.name][0].adUnitName.length <= 0 &&
              toSaveObject[el.name][0].adUnitId.length <= 0
            ) {
              toSaveObject[el.name] = [];
            }
          }
        }
      });

      // for (let key in toSaveObject) {
      //   let adUnitfilled;
      //   if (toSaveObject[key].length === 0 && !notReqFields.includes(key)) {
      //     flag = false;
      //     props.showAlertMsg(`Please fill ${key} field`);
      //     break;
      //   } else if (
      //     !notReqFields.includes(key) &&
      //     typeof toSaveObject[key] === "object"
      //   ) {
      //     if (isArr(toSaveObject[key])) {
      //       toSaveObject[key].forEach((elem, i) => {
      //         for (let sub_key in elem) {
      //           let disp;
      //           if (sub_key.includes("sizes")) {
      //             disp = sub_key.split("").pop();
      //             if (
      //               disp !== elem["adUnitDisplay"] &&
      //               elem["adUnitDisplay"] !== "B"
      //             ) {
      //               continue;
      //             }
      //           }
      //           if (
      //             sub_key === "adUnitName" &&
      //             elem[sub_key].includes("Please fill ad unit")
      //           ) {
      //             adUnitfilled = false;
      //           } else {
      //             adUnitfilled = true;
      //           }
      //           if (
      //             (elem[sub_key].length === 0 || !adUnitfilled) &&
      //             !notReqFields.includes(sub_key)
      //           ) {
      //             flag = false;
      //             props.showAlertMsg(
      //               `Please fill ${sub_key} field inside ${i + 1} ${key}`
      //             );
      //             break;
      //           }
      //         }
      //       });
      //     } else {
      //       for (let sub_key in toSaveObject[key]) {
      //         if (
      //           toSaveObject[key][sub_key].length === 0 &&
      //           !notReqFields.includes(sub_key)
      //         ) {
      //           flag = false;
      //           props.showAlertMsg(
      //             `Please fill ${sub_key} field inside ${key}`
      //           );
      //           break;
      //         }
      //       }
      //     }
      //   }
      // }
      if (props.editFlag) {
        let changes = JSON.parse(JSON.stringify(changedFields));
        const notMatched = trackChange(props.gcFormData, toSaveObject, changes);
        dispatch(setChangedFields(notMatched));
      }
      if (flag) {
        let event = {
          target: {
            name: "",
            value: "",
          },
        };
        console.log("Json", toSaveObject);
        event.target.name = "additionalDetails" + props.heading;
        event.target.value = toSaveObject;
        props.handleChange(event);
        props.handleClose("D" + props.index);
      }
    }
    props.setSaveClicked(false);
  }
  useEffect(() => {
    saveGenericCube();
  }, []);
  return <></>;
};

export default SaveGc;
