import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { Alert, Stack, Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import HeaderBidding from "../HeaderBidding/HeaderBidding";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import CricketWidget from "../CricketWidget/CricketWidget";
import EventsWidget from "../EventsWidget/EventsWidget";
import NewsBot from "../NewsBot/NewsBot";
import AppMonetization from "../AppMonetization/AppMonetization";
import AMPGists from "../AMPGists/AMPGists";
import ContentRecom from "../ContentRecom/ContentRecom";
import CancelIcon from "@mui/icons-material/Cancel";
import ProductEnable from "../../utils/ProductEnable";
import JsonLink from "../../utils/JsonLink";
import GenericCube from "../GenericCube/GenericCube";
import Override from "./Override";
import DirectCampaign from "../DirectCampaign/DirectCampaign";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddProductDetails(props) {
  // upload state
  const [upload, setUpload] = useState({});
  const [uploadClicked, setUploadClicked] = useState(false);
  // save state
  const [saveClicked, setSaveClicked] = useState(false);

  const [clientname, setClientName] = useState(props.formData.clientName);
  const [fieldSet, setFieldSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prodFields, setProdFields] = useState({});
  const [openOverride, setOpenOverride] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [gistUrl, setGistUrl] = useState("");
  const [error, setError] = useState("");
  //  changes track function
  let field_data = require(`../${props.heading}/ProductAdditionalField`);
  field_data = field_data.default[props.heading];
  /* custom Alert */
  const [alert, setAlert] = useState({
    message: "",
    show: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    show: false,
  });
  /* custom Alert */
  const showAlertMsg = (msg) => {
    setAlert((prev) => {
      return {
        ...prev,
        message: msg,
        show: true,
      };
    });
    setTimeout(() => {
      setAlert((prev) => {
        return {
          ...prev,
          message: "",
          show: false,
        };
      });
    }, 2000);
  };
  const showSuccessMsg = (msg) => {
    setSuccess((prev) => {
      return {
        ...prev,
        message: msg,
        show: true,
      };
    });

    setTimeout(() => {
      setSuccess((prev) => {
        return {
          ...prev,
          message: "",
          show: false,
        };
      });
    }, 2000);
  };
  const handleSave = () => {
    setSaveClicked(true);
  };
//for gists
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // on cancel click of the form
  const handleCancel = () => {
    field_data.forEach((field) => {
      if (field.addCount !== undefined) {
        field.addCount = 1;
      }
    });
    props.handleClose("D" + props.index);
  };

  // on json upload click
  const handleUpload = (e) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(e.target.files[0]);
  };

  // upload json
  function onReaderLoad(event) {
    try {
      var obj = JSON.parse(event.target.result);
      setFieldsData(obj);
    } catch (error) {
      console.error(error);
      showAlertMsg("Please upload a valid json file");
    }
  }

  // upload json
  function setFieldsData(obj) {
    setUpload(obj);
    setUploadClicked(true);
  }
  useEffect(() => {
    if (fieldSet) {
      setUpload({});
      setFieldSet(false);
    }
  }, [props.heading]);
  useEffect(() => {
    if (!fieldSet) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fieldSet]);
  useEffect(() => {
    setClientName(props.formData.clientName);
  }, [props.formData.clientName]);

  useEffect(() => {
    if (
      (props.heading === "VideoPlayer" || props.heading === "HeaderBidding") &&
      !prodFields[props.heading]
    ) {
      console.log("fetch " + props.heading + " json");
      const url = `https://cdn.unibots.in/dashfields/${props.heading}/productAdditionalFields.json`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setProdFields((prev) => {
            return { ...prev, [props.heading]: data[props.heading] };
          });
        });
    }
  }, [props.heading]);
  
  const handleSavedFile = () => {
    try {
      const jsonFile = require(`../../../../files/${
        props.heading + "/" + clientname + ".json"
      }`);
      setFieldsData(jsonFile);
    } catch (e) {
      showAlertMsg("Failed to load file.");
    }
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      fullScreen
    >
      {alert.show ? (
        <span style={{ position: "fixed", width: "70%", margin: "60px" }}>
          <Alert severity="error">{alert.message}</Alert>
        </span>
      ) : (
        <></>
      )}
      {success.show ? (
        <span style={{ position: "fixed", width: "70%", margin: "60px" }}>
          <Alert severity="success">{success.message}</Alert>
        </span>
      ) : (
        <></>
      )}
      {uploadClicked ? (
        <span style={{ position: "fixed", width: "70%", margin: "60px" }}>
          <Alert severity="info">
            Please wait while json is being uploaded
          </Alert>
        </span>
      ) : (
        <></>
      )}
      <DialogTitle
        sx={{
          borderBottom: "1px solid black",
          padding: 0,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ m: 2, alignItems: "center", justifyContent: "space-between" }}
        >
          {props.heading + " Additional Details"}
          {props.editFlag && (
            <JsonLink heading={props.heading} clientName={clientname} />
          )}
          {/* {props.heading === "VideoPlayer" && (
            <Button
              onClick={() => {
                setOpenOverride(true);
              }}
              variant="contained"
            >
              Override
            </Button>
          )} */}
          <ProductEnable heading={props.heading} />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          borderBottom: "1px solid black",
        }}
      >
        <Override
          open={openOverride}
          data={prodFields}
          setOpen={setOpenOverride}
        />
        {props.heading == "HeaderBidding" ? (
          <HeaderBidding
            editFlag={props.editFlag}
            hbFormData={props.formData["additionalDetails" + props.heading]}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            index={props.index}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
            prodFields={prodFields}
          />
        ) : props.heading == "VideoPlayer" ? (
          <VideoPlayer
            editFlag={props.editFlag}
            vpFormData={props.formData["additionalDetails" + props.heading]}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            index={props.index}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
            prodFields={prodFields}
            setVpJson={props.setVpJson}
          />
        ) : props.heading == "CricketWidget" ? (
          <CricketWidget
            editFlag={props.editFlag}
            crwFormData={props.formData["additionalDetails" + props.heading]}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            index={props.index}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
          />
        ) : props.heading == "NewsBot" ? (
          <NewsBot
            editFlag={props.editFlag}
            newsFormData={props.formData["additionalDetails" + props.heading]}
            index={props.index}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            showSuccessMsg={showSuccessMsg}
            heading={props.heading}
            index_inner={props.index_inner}
          />
        ) : props.heading == "EventsWidget" ? (
          <EventsWidget
            editFlag={props.editFlag}
            eventFormData={props.formData["additionalDetails" + props.heading]}
            index={props.index}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
          />
        ) : props.heading == "ContentRecom" ? (
          <ContentRecom
            editFlag={props.editFlag}
            contentFormData={
              props.formData["additionalDetails" + props.heading]
            }
            index={props.index}
            clientname={props.formData.clientName}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            index_inner={props.index_inner}
            saveClicked={saveClicked}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            setSaveClicked={setSaveClicked}
            heading={props.heading}
          />
        ) : props.heading == "AppMonetization" ? (
          <AppMonetization />
        ) : props.heading == "GenericCube" ? (
          <GenericCube
            editFlag={props.editFlag}
            gcFormData={props.formData["additionalDetails" + props.heading]}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            index={props.index}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
          />
        ) : props.heading == "DirectCampaign" ? (
          <DirectCampaign
            editFlag={props.editFlag}
            dcFormData={props.formData["additionalDetails" + props.heading]}
            clientname={clientname}
            upload={upload}
            uploadClicked={uploadClicked}
            setUploadClicked={setUploadClicked}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            fieldSet={fieldSet}
            setFieldSet={setFieldSet}
            index={props.index}
            handleChange={props.handleChange}
            handleClose={props.handleClose}
            showAlertMsg={showAlertMsg}
            heading={props.heading}
            showSuccessMsg={showSuccessMsg}
          />
        ) : props.heading == "AMPGists" ? (
          <AMPGists 
          editFlag={props.editFlag}
          gistFormData={props.formData["additionalDetails" + props.heading]}
          clientname={clientname}
          upload={upload}
          uploadClicked={uploadClicked}
          setUploadClicked={setUploadClicked}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          fieldSet={fieldSet}
          setFieldSet={setFieldSet}
          index={props.index}
          handleChange={props.handleChange}
          handleClose={props.handleClose}
          showAlertMsg={showAlertMsg}
          heading={props.heading}
            />
        ) : (
          <span></span>
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <i style={{ marginRight: "30px", fontSize: "20px" }}>
            Upload a JSON or fill in details manually
          </i>
          <input
            style={{ cursor: "pointer" }}
            type="file"
            onChange={handleUpload}
            accept=".json"
          />
        </Box>
        <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Gist</DialogTitle>
        <DialogContent>
          {gistUrl ? (
            <p>
              Successfully created Gists:{" "}
              <a href={gistUrl} target="_blank" rel="noopener noreferrer">
                {gistUrl}
              </a>
            </p>
          ) : (
            <>
              <p>{error}</p>
              <Button onClick={handleCloseModal} color="primary">
                copy url
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <Box sx={{ display: "flex", gap: "20px" }}>
          {/* <Button onClick={handleSavedFile}>Load Saved File</Button> */}
          <Button
            variant="outlined"
            color="error"
            onClick={handleCancel}
            endIcon={<CancelIcon />}
          >
            <Box sx={{ height: "22px" }}> Cancel</Box>
          </Button>
          <Button variant="contained" onClick={() => handleSave()}>
           {props.heading !== "AMPGists" ? 'Save' : 'Save Gist Details'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AddProductDetails);
