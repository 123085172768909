const productAdditionalFields = {
  GenericCube: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "client_domain",
      label: "client domain",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
    },
    {
      name: "channelId",
      label: "Channel Id",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "mybotLang",
      label: "mybotLang",
      type: "Input",
      default: "english",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "ub_delayCube",
      label: "ub_delayCube",
      type: "Input",
      default: 0,
      isNum: true,
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "disabled",
      label: "disabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "cubeEnabled",
      label: "Cube Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "chooseDevice",
      label: "Choose Device",
      type: "radio",
      default: "both",
      selectOptions: ["both", "pc", "mb"],
      isBool: false,
      isRequired: false,
    },
    {
      name: "Sticky_Ad",
      label: "Sticky Ad",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "WidgetPosition",
      label: "Widget Position Desktop",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "WidgetPositionMob",
      label: "Widget Position Mobile",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: false,
    },
    {
      name: "DesktopBottomPixel",
      label: "Desktop Bottom Pixel",
      type: "Input",
      default: 60,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 60,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "DesktopRightPixel",
      label: "Desktop Right Pixel",
      type: "Input",
      default: 20,
      selectOptions: null,
      isBool: false,
      isNum: true
    },
    {
      name: "DesktopLeftPixel",
      label: "Desktop Left Pixel",
      type: "Input",
      default: 20,
      selectOptions: null,
      isBool: false,
      isNum: true
    },
    {
      name: "cubeSizeM",
      label: "Cube Size Mobile",
      type: "Input",
      default: 1,
      selectOptions: null,
      isBool: false,
      isNumFloat: true
    },
    {
      name: "cubeSizeD",
      label: "Cube Size Desktop",
      default: 1,
      type: "Input",
      selectOptions: null,
      isBool: false,
      isNumFloat: true
    },
    {
      name: "addLogo",
      label: "Add Logo",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: false
    },
    {
      name: "MobileRightPixel",
      label: "Mobile Right Pixel",
      type: "Input",
      default: 10,
      selectOptions: null,
      isBool: false,
      isNum: true
    },
    {
      name: "MobileLeftPixel",
      label: "Mobile Left Pixel",
      type: "Input",
      default: 10,
      selectOptions: null,
      isBool: false,
      isNum: true
    },
    {
      name: "ampEnable",
      label: "Amp Enable",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "cubeType",
      label: "Cube Type",
      type: "Dropdown",
      default: "mgid",
      selectOptions: [
        "game",
        "nativclick",
        "colombia",
        "outbrain",
        "mgid",
        "dc",
        "mgid_v2"
      ],
      isBool: false,
    },
    {
      name: "mgid_v2_Cube_Script",
      label: "MGID V2 Cube Script",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isNum: false
    },
    {
      name: "directAdType",
      label: "directAdType",
      type: "Dropdown",
      default: "mgid",
      selectOptions: ["game", "nativclick", "colombia", "outbrain", "mgid"],
      isBool: false,
    },
    {
      name: "cubeClsBtnM",
      label: "Cube Close Btn Mb",
      type: "radio",
      default: "left",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "cubeClsBtnD",
      label: "Cube Close Btn PC",
      type: "radio",
      default: "left",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "CUSTOM_ADS_JS",
      label: "Custom Ads Js",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
      line: true,
    },
    {
      name: "cubeData",
      label: "Cube Data",
      type: "objectgroup",
      fields: [
        {
          name: "adUnitName",
          label: "Script src",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          style: { width: "70%" },
        },
        {
          name: "customAdId",
          label: "customAdId",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "ad_mappings",
      label: "Ad Mappings",
      type: "objectgroup",
      fields: [
        {
          name: "adUnitDisplay",
          label: "AD Unit Display",
          type: "Dropdown",
          default: "B",
          selectOptions: ["B", "M", "D"],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "adUnitName",
          label: "Ad Unit Name",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
          style: { width: "60%" },
        },
        {
          name: "adUnitId",
          label: "Div Id",
          type: "AutoComplete",
          default: "",
          addCustom: true,
          selectOptions: [
            "div-ub-1",
            "ub-anchor-da",
            "ub-parallax-da",
            "ub-popup-da",
            "ub-inimage-da",
            "ub-left-ad",
            "ub-right-ad",
            "ub-balloon",
            "ub-interstitial",
            "unibots-direct_1x1",
            "unibots-direct_1x2",
            "unibots-direct_1x3",
            "unibots-direct_2x1",
            "unibots-direct_2x2",
            "unibots-direct_EOA",
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "customAdId",
          label: "customAdId",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          style: { width: "60%" },
        },
        {
          name: "targetDiv",
          label: "Custom Div Targeting",
          type: "objectgroup",
          default: {},
          index: "static",
          fields: [
            {
              name: "elmType",
              label: "elmType",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "selector",
              label: "selector",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "childElm",
              label: "childElm",
              type: "Input",
              default: "",
              selectOptions: null,
              isBool: false,
              saveType: "string",
            },
            {
              name: "childNum",
              label: "childNum",
              type: "Input",
              default: 0,
              selectOptions: null,
              isBool: false,
              isNum: true,
              saveType: "string",
            },
          ],
          isRequired: false,
          addButton: false,
        },
        {
          name: "disabled",
          label: "disabled",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
        {
          name: "unibotsLogo",
          label: "Unibots Logo",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: false,
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
  ],
};
export default productAdditionalFields;
